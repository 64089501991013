<template>
	<div class="container-fluid relative animatedParent animateOnce my-3">
		<div class="tab-pane animated fadeInUpShort show active go">
			<div class="row my-3">
				<div class="col-md-12">
					<div class="card r-0 shadow">
						<div class="card-header">
							
							
							<div class="row my-3">
								<div class="col-md-6">
									<!-- 搜索 start -->
									<div class="input-group w-100 m-3 form-inline"><div class="input-group-btn">
										<input v-model="query" class="form-control p-2" placeholder="搜索" type="text">
										
										<button @click="search" type="button" class="btn btn-secondary ml-2"><i class="icon-search"></i>
										</button>
										</div>
									</div>
									<!-- 搜索 end -->
								</div>
								<div class="col-md-6 ">
									<div class="float-right m-3">
										<button  @click="add" type="button" class="btn btn-secondary">创建企业码</button>
									</div>	
								</div>	
							</div>	
						</div>
	
						<div class="table-responsive">
							<div>
								<table class="table table-striped table-hover r-0 mb-0">
									<thead>
										<tr class="no-b">
											<th style="width: 60px">
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" id="checkedAll" class="custom-control-input"><label class="custom-control-label" for="checkedAll"></label>
												</div> -->
												 <div class="d-none d-lg-block">序号</div>
											</th>
											<th>
												<div class="d-none d-lg-block">企业</div>
											</th>
											<!-- <th>
												<div class="d-none d-lg-block">企业简称</div>
											</th> -->
											
											<th>
												<div class="d-none d-lg-block">企业ID/账户ID</div>
											</th>
											
											<!-- <th>
												<div class="d-none d-lg-block">账户ID</div>
											</th> -->
											
											<th>
												<div class="d-none d-lg-block">鲨鲨购代码</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">企业签约链接</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">员工激活链接</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">状态</div>
											</th>
											
											<th></th>
										</tr>
									</thead>
	
									<tbody>
										<template v-for="(item,index) in enterpriselist">
										<tr v-bind:key="index" 
										:class="{'blue lighten-5':selectedId == item.id}" 
										v-if="item.enterprise_id != '2088241079074075'"
										>
											<td>
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input checkSingle" id="user_id_32" required=""><label class="custom-control-label" for="user_id_1"></label>
												</div> -->
												<div class="d-none d-lg-block">{{index+1}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">
												{{item.enterprise_alias}}
												<br>	
												{{item.enterprise_name}}
												<br>
												
												{{item.identity}}
												</div>
											</td>
											
											<!-- <td>
												<div class="d-none d-lg-block">{{item.enterprise_code}}</div>
											</td> -->
											
											<td>
												<div class="d-none d-lg-block">
												<span class="font-weight-bold">{{item.enterprise_id}}</span>
												<br>
												{{item.account_id}}
												<!-- <br>
												{{item.sasago_code}} -->
												</div>
											</td>
											
											<!-- <td>
												<div class="d-none d-lg-block">{{item.account_id}}</div>
											</td> -->
											
											<td>
												<div class="d-none d-lg-block">{{item.sasago_code}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.auth_url}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.sign_url}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">
												<template v-if="item.activate == 'ACTIVATED'">
													<span class="text-success">已激活</span>
												</template>
												<template v-if="item.activate == 'UNACTIVATED'">
													<span class="text-secondary">未激活</span>
												</template>
												</div>
											</td>
											
											<td>
												<button @click.stop="edit(index)" type="button" class="btn btn-outline-primary btn-xs ml-2"> 编辑 </button>
												<!-- <button @click.stop="configReady(index)" type="button" class="btn btn-outline-primary btn-xs ml-2"> 配置 </button> -->
												<button @click.stop="removeReady(index)" type="button" class="btn btn-outline-danger btn-xs ml-2"> 注销 </button>
											</td>
										</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
	
			<!-- <div class="row">
				<div class="col-sm-12 col-md-5">
					<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
						显示  {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
					</div>
				</div>
				<div class="col-sm-12 col-md-7">
					
					<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>
					
				</div>
			</div> -->
	
		</div>
		
		<!--Add New Message Fab Button-->
		<!-- <div @click="add" class="btn-fab btn-fab-md fab-right fab-right-bottom-fixed shadow btn btn-secondary"><i class="icon-add"></i></div> -->
		
		<!-- modal start -->
		<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">{{selectedIndex ==-1?'添加企业码':'修改企业码'}}</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div>
		          <div class="form-group" v-if="!enterprise.id">
		            <label for="">企业名称</label>
		            <input v-model="enterprise.enterprise_name" type="text" class="form-control"  placeholder="企业名称">
		            <div class="valid-feedback">
						
		            </div>
		          </div>
		          
				  <div class="form-group">
				    <label for="">企业简称</label>
				    <input v-model="enterprise.enterprise_alias" type="text" class="form-control" placeholder="企业简称">
				    <small class="form-text text-muted">
						企业简称会显示在企业代付款界面
				    </small>
				  </div>
				  
				  <div class="form-group" v-if="!enterprise.id">
				    <label for="">企业管理员支付宝帐号</label>
				    <input v-model="enterprise.identity" type="text" class="form-control" placeholder="企业支付宝帐号">
				    <small class="form-text">
				    	必须是企业名称同名的企业支付宝帐号
				    </small>
				  </div>
				  
				  <div class="form-group" v-show="!enterprise.id">
				    <input v-model="enterprise.create_fund_account" type="checkbox" name="status" value="1" class="">
				    <label for="" class="text-danger">管理员支付宝为企业出资账户</label>
				  </div>
				  
		        </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		        <!-- <button v-if="selectedIndex != -1" @click="removeReady(selectedIndex)" type="button" class="btn btn-danger"> 删除 </button> -->
				 <button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button>
		      </div>
		    </div>
		  </div>
		</div>
		
	</div>		
</template>

<script>
module.exports = {
    data: function() {
        return {
			title: '企业码',
			
			token: '',
			
			// setting: {},
			// outletlist: [],
			
			ent_id: 0,
			dept_id: 0,
			
			ent_name: '',
			dept_name: '',
			user_role: '',
			user_name: '',
			
			enterpriselist: [],
			query: '',
			
			enterprise: {
				// identity_type: 'ALIPAY_LOGON_ID'//后端设置
			},
			selectedIndex: -1,
			
			selectedId: 0,
			
			disabled: false,
			
			////
			phone_setting: {
				enterprise_name: '',
				phone_number: '',
			},
			
			create_fund_account: 0,
        }
    },
    props: {
      
    },
	
	// components: {
	// 	// 将组建加入组建库
	// 	// 'v-pagination': 'url:./component/my-page.vue',
	// 	'v-sibebar': 'url:/component/aside.vue',
	// 	'v-navbar': 'url:/component/navbar.vue',
	// 	'v-right-sidebar': 'url:/component/right-sidebar.vue'
	// },
	
	created: function() {
		console.log('--- created --- ')
		let self = this;
		
		//1.检查登录
		let token = localStorage.getItem('TOKEN');
		// if (!token) {
		// 	return;
		// }
		self.token = token;
		
		//2.取得店铺信息
		let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
		console.log(lsLoginData.dept_name)
		self.ent_id = lsLoginData.ent_id;
		console.info('--- this.$store.state ->',this.$store.state)
		
		// self.dept_id = this.$store.state.current_outlet_id || lsLoginData.dept_id;
		
		// self.ent_name = lsLoginData.ent_name;
		// self.dept_name = lsLoginData.dept_name;
		
		// self.user_name = lsLoginData.user_account;
		
		// self.setting = JSON.parse(localStorage.getItem('SETTING'));
		
		// self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
		// console.log('--- outlet list ->', self.outletlist)
	},
	mounted: function() {
		console.log('--- mounted --- ')
		let self = this;
		
		//1.检查登录
		// let token = localStorage.getItem('TOKEN');
		// if (!self.token) {
		// 	console.log('--- mounted jump  --- ')
		// 	location.href = 'index.html';
		// 	return;
		// }
		
		self.initData();
	},
    methods:{
    	initData() {
    		let self = this;
    	
    		//
    		this.axios.get(this.GLOBAL.baseURI + 'getEnterpriseList', {
    				params: {
    					token: self.token,
    					// dept_id: self.dept_id,
    					// query: self.query,
    					// current_page: self.current_page,
    					// page_size: self.page_size,
    				}
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    				if (data.code == 200) {
    					self.enterpriselist = data.data.list;
    					__LIST = data.data.list;
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    		//END
    	
    	},
    	
    	search() {
    	
    		let self = this;
    		self.enterpriselist = __LIST.filter(item => item.enterprise_name.indexOf(self.query) != -1);
    	},
    	
    	add() {
    		var self = this;
    	
    		// location.href = 'editcategory.html';	
    	
    		self.selectedIndex = -1;
    		self.enterprise = {
				id: 0,
				parent_enterprise_id: "-1",
				enabled: 1,
			};
			
    		$('#modal').modal('show');
    	},
    	
    	edit(index) {
    		console.log(index);
    		let self = this;
    	
    		self.selectedIndex = index;
    		self.enterprise = Object.assign({}, self.enterpriselist[index]);
			self.selectedId = self.enterprise.id;
    		console.log(self.selectedIndex, self.enterprise);
    	
    		self.oldName = self.enterprise.enterprise_name;
    	
    		$('#modal').modal('show');
    	},
    	
    	save() {
    		let self = this;
    		console.log(self.enterprise);
    		// return;
    		console.log(self.oldName)
			
			if(self.disabled) return;
    	
    		//校验
    		if (!self.enterprise.enterprise_name) {
				this.$toasted.error('企业名称不能为空', {
					position: 'top-center',
				}).goAway(2000)
    			return;
    		}
			
			if (!self.enterprise.identity) {
				this.$toasted.error('企业支付宝能为空', {
					position: 'top-center',
				}).goAway(2000)
				return;
			}
			
			//管理员支付宝为企业出资账户
			self.enterprise.create_fund_account = self.enterprise.create_fund_account?1:0;
			console.log('--- self.enterprise.create_fund_account ->',self.enterprise.create_fund_account)
    	
    		//更新时，如果没有改名，不提交
    // 		if (self.selectedIndex != -1 &&
    // 			self.enterprise.enterprise_name.toLowerCase() == self.oldName.toLowerCase()) {
    // 			// alert('分类名称没有更改')
				// this.$toasted.error('名称没有更改', {
				// 	position: 'top-center',
				// }).goAway(2000)
				
    // 			return;
    // 		}
    	
    		//同名校验
    	// 	for (let i = 0; i < self.enterpriselist.length; i++) {
    	
    	// 		if (self.selectedIndex == i) continue; //编辑时，忽略自身
    	
    	// 		if (self.enterpriselist[i].enterprise_name.toLowerCase() == self.enterprise.enterprise_name.toLowerCase()) {
    	// 			// alert('分类名称不能不能重复');
					
					// this.$toasted.error('企业名称不能不能重复', {
					// 	position: 'top-center',
					// }).goAway(2000)
					
    	// 			// console.log(__LIST[self.selectedIndex].category_name)
    	// 			self.enterprise.enterprise_name = self.oldName;
    	// 			return;
    	// 		}
    	// 	}
    	
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
				
				ent_id: self.enterprise.id || 0,
				
    			enterprise_name: self.enterprise.enterprise_name,
				enterprise_alias: self.enterprise.enterprise_alias,
				identity: self.enterprise.identity,
				create_fund_account: self.enterprise.create_fund_account,
				enabled: self.enterprise.enabled?1:0,
				nonce_str: self.enterprise.nonce_str || ''
    		}
			
			console.log('--- postData ->',postData)
    	
    		let _url = self.selectedIndex == -1 ? 'addEnterprise' : 'updateEnterprise';
    		this.axios.get(this.GLOBAL.baseURI + _url, {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
						
						if(data.data.result_code == 'success'){
							if (self.selectedIndex == -1) {
								self.enterpriselist.unshift(data.data.enterprise);
							} else {
								// self.enterpriselist.splice(self.selectedIndex, 1, data.data);
								console.log('--- 更新企业码返回 data.data ->',data.data.enterprise)
								self.$set(self.enterpriselist, self.selectedIndex, data.data.enterprise)
							}
							    	
							    	
							// alert('保存成功')
							self.$toasted.success('保存成功', {
								position: 'top-center',
							}).goAway(2000)
							    	
							$('#modal').modal('hide');
						}
						else{
							self.$toasted.error(data.data.result_msg, {
								position: 'top-center',
							}).goAway(2000)
						}
    					
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
						$('#modal').modal('hide');
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    	},
		
		
		removeReady(index){
			console.log(index);
			let self = this;
			
			self.selectedIndex = index;
			self.enterprise = Object.assign({}, self.enterpriselist[index]);
			
			self.$confirm({
				title: '警告',
				message: '企业注销，并清理企业数据，企业及员工不再能使用企业码服务。',
				button: {
					no: '取消',
					yes: '确定',
				},
				callback:(res)=>{
					console.log(res)
					if(res){
						self.remove();
					}
					
				}
			});
		},
    	
    	
    	remove() {
    		let self = this;
    		console.log(self.enterprise);
    		// return;
    	
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
    			// enterprise_id: self.enterprise_id,//后端TOKEN中取得
				// enterprise_id: self.enterprise.enterprise_id,
    			ent_id: self.enterprise.id,
				nonce_str: self.enterprise.nonce_str || ''
    		}
    	
    		this.axios.get(this.GLOBAL.baseURI + 'delEnterprise', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
    					if(data.data.result_code == 'success'){
							self.enterpriselist.splice(self.selectedIndex, 1);
							    	
							self.$toasted.success('删除成功', {
								position: 'top-center',
							}).goAway(2000)
							    	
							// $('#modal').modal('hide');
						}
						else{
							self.$toasted.error(data.data.result_msg, {
								position: 'top-center',
							}).goAway(2000)
						}
    				} 
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    	},
		
    	
    	onOutletChangeListener(e) {
    		let self = this;
    		console.log('--- outlet ID->', e)
    		self.outlet_id = e;
    	
    		self.initData();
    	},
    }
}
</script>

<style>
</style>
